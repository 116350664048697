import { admin, publicApi, admin2 } from '@/api/instance'

export const GetOrgs = async ({ code, name, limit } = {}) => {
  const res = await publicApi({
    method: 'get',
    url: '/org',
    params: {
      code,
      name,
      limit,
    },
  })

  return res.data
}

export const GetOrgsCount = async () => {
  const res = await publicApi({
    method: 'get',
    url: '/org/count',
  })

  return res.data.count
}

export const GetOrgsByCode = async ({ code }) => {
  const res = await publicApi({
    method: 'get',
    url: `/org/findOneByCode/${code}`,
  })

  return res.data
}

export const EmailLogin = async ({
  orgCode,
  email,
  password,
  agreeTerms,
  recaptchaVersion,
  recaptchaToken,
}) => {
  return await admin2({
    method: 'POST',
    url: '/user/login/byEmail',
    data: {
      orgCode,
      email,
      password,
      agreeTerms,
      recaptchaVersion,
      recaptchaToken,
    },
  })
}

export const LoginByUserAuthGrant = async ({
  orgCode,
  code,
}) => {
  return await admin2({
    method: 'POST',
    url: '/user/login/byUserAuthGrant',
    data: {
      orgCode,
      code,
    },
  })
}

export const Logout = async () => {
  return await admin2({
    method: 'post',
    url: '/user/logout',
  })
}

export const UpdateAuthPhone = async ({ shopId, id, phone }) => {
  const res = await admin2({
    method: 'put',
    url: `${shopId}/member/${id}/authPhone`,
    data: {
      phone,
    },
  })

  return res
}

export const DeleteAuthPhone = async ({ shopId, id }) => {
  const res = await admin2({
    method: 'delete',
    url: `${shopId}/member/${id}/authPhone`,
  })

  return res
}

//= > 搜尋店家會員
export const FindShopMember = async ({
  shopId,
  id,
}) => {
  const res = await admin2({
    method: 'get',
    url: `${shopId}/member/${id}`,
  })

  return res
}
